import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

type GetCodeType = {
    email?: string
    name?: string
    phone?: string
    gym_id?: number
}

type SuccessResultType = {
    result: number
    message?: string
}

type LoginPropsType = {
    email: string
    code: string
}

type LoginResultType = {
    result: number,
    token?: string,
    user_id?: number,
    fullname?: number
    message?: string
}

type ClientCheckResultType = {
    result: boolean,
    user_id: number
}

export type UpdateType = {
    name?: string,
    last_name?: string,
    email?: string,
    phone?: string
    token: string
}

type TokenInfoResponseType = {
    email: string
    id: number
    last_name: string
    name: string
    phone: string
    result: number
}

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_HOST + '/auth/',
        mode: 'cors'
    }),
    endpoints: (build) => ({
        getCode: build.mutation<SuccessResultType, GetCodeType>({
            query: (params) => {
                params.gym_id = 138
                if (!params.phone) { // It's PocketSport ¯\_(ツ)_/¯
                    params.phone = params.email
                    delete params.email
                }

                return {
                    url: '/getcode',
                    params
                }
            }
        }),
        login: build.mutation<LoginResultType, LoginPropsType>({
            query: ({email, code}) => ({
                url: '/login',
                params: {
                    gym_id: 138,
                    phone: email,  // It's PocketSport ¯\_(ツ)_/¯
                    code
                }
            })
        }),
        clientCheck: build.mutation<ClientCheckResultType, string>({
            query: (email) => ({
                url: '/client/check',
                params: {
                    gym_id: 138,
                    email
                }
            })
        }),
        tokenInfo: build.query<TokenInfoResponseType, string>({
            query: (token) => ({
                url: '/info',
                params: {
                    token
                }
            })
        }),
        update: build.mutation<SuccessResultType, UpdateType>({
            query: (params) => ({
                url: '/update',
                params: params
            })
        })
    })
})

export const {
    useGetCodeMutation,
    useLoginMutation,
    useClientCheckMutation,
    useTokenInfoQuery,
    useUpdateMutation
} = authApi
