import React, {FC} from 'react';
import { Button } from '../../styles/components';
import StyleProfile, {RecordItem} from '../../styles/StyleProfile';
import {RecordType} from '../../types';
import {dateToPeopleView, removeSeconds} from '../../utils';
import {useLazyCancelTrainingQuery} from '../../services/ScheduleServices';
import {useSelector} from 'react-redux';
import {RootState, useAppDispatch} from '../../redux/store';
import {setPopup} from '../../redux/slices/uiSlice';

type RecordsProps = {
    title: string
    records: RecordType[]
}

const Records: FC<RecordsProps> = ({title, records}) => {
    const {token} = useSelector((state: RootState) => state.auth)
    const [cancelTraining] = useLazyCancelTrainingQuery();
    const dispatch = useAppDispatch()

    const getStateByRecordInfo = (record: RecordType) => {
        let state: string
        let paidState: string

        if (record.charged || record.isPaid || record.isActivatePack || record.pack_id) {
            paidState = 'Оплачена'
        } else {
            paidState = 'Не оплачена'
        }

        if (record.in_past) {
            state = record.visited ? 'Посещена' : 'Не посещена'
        } else {
            state = 'Записан'
        }

        return `${state} (${record.deleted ? 'Отменена, ' : ''}${paidState})`
    }

    const generateRecordsHTML = (records: RecordType[]) => {

        const cancelHandler = async (recordId: number) => {
            const {data} = await cancelTraining({recordId: recordId, token: token});

            if (data && data.result) {
                dispatch(setPopup({
                    type: 'success',
                    text: 'Тренировка успешно отменена'
                }))
                window.location.reload()
            } else {
                dispatch(setPopup({
                    type: 'error',
                    text: 'Произошла ошибка при отмене тренировки'
                }))
            }
        }

        return records.map((record, index) =>
            <StyleProfile.RecordItem key={index}>
                <RecordItem.DateTime>
                    <span>{dateToPeopleView(record.activitydate, 'long')}</span>
                    <span>{removeSeconds(record.starttime)} - {removeSeconds(record.endtime)}</span>
                </RecordItem.DateTime>
                <RecordItem.Activity>{record.activity_name}</RecordItem.Activity>
                <RecordItem.Coach>{record.coach}</RecordItem.Coach>
                <RecordItem.Row>
                    <RecordItem.State>{getStateByRecordInfo(record)}</RecordItem.State>
                    {!record.in_past && !record.deleted &&
                      <Button
                        colored={'#DF0F0F'}
                        marginBottom={0}
                        fontSize={14}
                        padding={'6px'}
                        onClick={() => cancelHandler(record.id)}
                      >Отменить</Button>
                    }
                </RecordItem.Row>
            </StyleProfile.RecordItem>
        )
    }

    return (
        <StyleProfile.ActiveRecords>
            <h2>{title}</h2>
            {generateRecordsHTML(records)}
        </StyleProfile.ActiveRecords>
    );
};

export default Records;
