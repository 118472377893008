import {FC} from 'react';
import {StyledScheduleCard} from '../styles/components';
import moment from 'moment';


type ScheduleCardPropsType = {
    id: number;
    startTime: string;
    endTime: string;
    trainer: string;
    trainingName: string;
    trainingDate: string;
    hasRecord: boolean;
    isAvaiable: boolean;
    onClick: (id: number, trainingDate: string, isRecorded: boolean) => void;
}

const ScheduleCard: FC<ScheduleCardPropsType> = ({
    id,
    startTime,
    endTime,
    trainingName,
    trainer,
    trainingDate,
    hasRecord,
    isAvaiable,
    onClick,
}) => {

    const formatTime = (time: string): string => {
        return time.slice(0,5);
    }

    const checkDate = (date: string): boolean => {
        const currentDate = moment(new Date());
        const trainingDate = moment(date);
        const diff = trainingDate.diff(currentDate, 'seconds');
        return diff < 0;
    }

    return (
        <StyledScheduleCard.Wrapper
            hasRecord={hasRecord}
            IsAvaiable={isAvaiable}
            isDisabled={checkDate(`${trainingDate} ${startTime}`)}
            onClick={() => onClick(id, trainingDate, hasRecord)}
        >
            <StyledScheduleCard.Content>
                <StyledScheduleCard.Time
                    IsAvaiable={isAvaiable}
                    hasRecord={hasRecord}
                    isDisabled={checkDate(`${trainingDate} ${startTime}`)}
                >
                    {`${formatTime(startTime)} - ${formatTime(endTime)}`}
                </StyledScheduleCard.Time>
                {hasRecord ? (
                  <StyledScheduleCard.RecordedLabel>
                    Записан
                  </StyledScheduleCard.RecordedLabel> 
                ) : null}
            </StyledScheduleCard.Content>
            <StyledScheduleCard.Content>
                <StyledScheduleCard.TrainerName
                    IsAvaiable={isAvaiable}
                    hasRecord={hasRecord}
                    isDisabled={checkDate(`${trainingDate} ${startTime}`)}
                >
                    {trainer}
                </StyledScheduleCard.TrainerName>
            </StyledScheduleCard.Content>
            <StyledScheduleCard.Content>
                <StyledScheduleCard.Title
                    IsAvaiable={isAvaiable}
                    hasRecord={hasRecord}
                    isDisabled={checkDate(`${trainingDate} ${startTime}`)}
                >
                    {trainingName}
                </StyledScheduleCard.Title>
            </StyledScheduleCard.Content>
        </StyledScheduleCard.Wrapper>
    )
}

export default  ScheduleCard;
